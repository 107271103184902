import { ViewEncapsulation, Component, OnInit, OnDestroy, Input, Inject, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import { BaseWrapperDirective } from 'src/app/shared/classes/base-wrapper';
import { ReportsService, WidgetType } from 'src/app/auto-cx/services/reports-service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Helper } from 'src/app/shared/helpers/helper';

@Component({
    selector: 'app-auto-cx-report-view',
    templateUrl: './auto-cx-report-view.component.html',
    styleUrls: ['./auto-cx-report-view.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AutoCxReportViewComponent extends BaseWrapperDirective implements OnInit, OnDestroy {

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AutoCxReportViewComponent>,
        private activateRoute: ActivatedRoute,
        private reportsService: ReportsService,
        private helper: Helper
    ) {
        super();
    }

    linkSharingId: string;
    reportVisualization: any;

    multiplesLookup: any = {};
    tooltipGraph: any = {};

    isReportLoading: boolean = false;

    ngOnInit() {
        this.isReportLoading = true;
        if (this.data?.testId) {
            this.subs.sink = this.reportsService.getReportById(this.data.testId).subscribe((report) => {
                this.isReportLoading = false;
                this.reportVisualization = this.mapReport(report);
            }, (error) => {
                this.isReportLoading = false;
            })
        } else {
            let linkSharingId = this.activateRoute.snapshot.paramMap.get('linkSharingId');
            if (linkSharingId !== null) {
                this.subs.sink = this.reportsService.getReportByLinkShareId(linkSharingId).subscribe((report) => {
                    this.isReportLoading = false;
                    this.reportVisualization = this.mapReport(report);
                }, (error) => {
                    this.isReportLoading = false;
                })
            } else {
                this.isReportLoading = false;
            }
        }
    }

    private mapReport(report: any) {
        return {
            id: report.id,
            name: report.name,
            description: report.description,
            systems: report.systems.map((system, i) => {
                return {
                    id: system.id,
                    name: system.name,
                    widgets: system.widgets.map((widget, j) => this.mapWidgetToGraph(widget, [i, j]))
                }
            })
        }
    }

    private mapWidgetToGraph(widget: any, idx: any) {
        let graphObject;
        switch (widget.type) {
            case WidgetType.ZONE_FINAL_AIRFLOW_TEMPS:
                graphObject = this.plotFinalAirflowTemps(widget)
                break;

            case WidgetType.ZONE_CONDITIONING_RATES:
                graphObject = this.plotZoneConditioningRates(widget)
                break;

            case WidgetType.ZONE_FINAL_AIRFLOW_TEMP_CORRELATIONS:
            case WidgetType.ZONE_CONDITIONING_RATE_CORRELATIONS:
                graphObject = this.plotCorrelations(widget, idx)
                break;
            case WidgetType.SYSTEM_TRENDS:
                graphObject = this.plotSystemTrends(widget)
                break;
            case WidgetType.ECONOMIZER_TRENDS:
                graphObject = this.plotEconomizerTrends(widget)
                break;
            case WidgetType.TREND_CHART_BUILDER:
                graphObject = this.plotTrendChart(widget)
                break;
            default:
                graphObject = {
                    data: [],
                    layout: {}
                };
        }
        return graphObject
    }

    plotFinalAirflowTemps(widget: any) {
        let data = widget.data;
        let tempTraces = data.map((stageData, index) => {
            return {
                x: stageData.x,
                y: stageData.y?.map(val => this.helper.parseNumeric(val, 3)),
                name: stageData.name,
                type: 'bar',
                mode: 'lines+points',
                marker: { color: this.reportsService.getTraceColor(index) }
            }
        });
        let tempBoxPlots = tempTraces.map((stageData) => {
            return {
                y: stageData.y?.map(val => this.helper.parseNumeric(val, 3)),
                name: stageData.name,
                type: 'box',
                marker: { color: stageData.marker.color }
            }
        });
        return {
            name: widget.name,
            data: [...tempTraces, ...tempBoxPlots],
            layout: {
                barmode: 'group',
                hoverlabel: { bgcolor: "#rgb(40, 40, 40)", namelength: -1 },
                margin: { t: 50, l: 50, r: 50, b: 60 },
                height: 300,
                font: {
                    family: 'Lato, sans-serif',
                    size: 10,
                    color: '#7f7f7f'
                },
                legend: { "orientation": "h", "y": 1.2, "x": 0.0 }
            }
        }
    }

    plotZoneConditioningRates(widget: any) {
        let data = widget.data;
        let rateTraces = data.map((stageData, index) => {
            return {
                x: stageData.x,
                y: stageData.y?.map(val => this.helper.parseNumeric(val, 3)),
                name: stageData.name,
                type: 'bar',
                mode: 'lines+points',
                marker: { color: this.reportsService.getTraceColor(index) }
            }
        })
        let rateBoxPlots = rateTraces.map((stageData) => {
            return {
                y: stageData.y?.map(val => this.helper.parseNumeric(val, 3)),
                name: stageData.name,
                type: 'box',
                marker: { color: stageData.marker.color }
            }
        });
        return {
            name: widget.name,
            data: [...rateTraces, ...rateBoxPlots],
            layout: {
                barmode: 'group',
                hoverlabel: { bgcolor: "#rgb(40, 40, 40)", namelength: -1 },
                margin: { t: 50, l: 50, r: 50, b: 60 },
                height: 300,
                font: {
                    family: 'Lato, sans-serif',
                    size: 10,
                    color: '#7f7f7f'
                },
                legend: { "orientation": "h", "y": 1.2, "x": 0.0 }
            }
        }
    }

    plotCorrelations(widget: any, idx: any) {
        try {
            let data = widget.data;
            let xValues = data.stages;
            let yValues = widget.data.stages.slice().reverse();
            let zValues = widget.data.correlationMatrix.map(row => row.map(val => this.helper.parseNumeric(val, 3)));
            let colorscaleValue = [[0, '#043780'], [1, '#8abaff']];

            let multiples = data.multiples;
            let stages = data.stages;
            let multiplesMatrix = [];
            while (multiples.length) multiplesMatrix.push(multiples.splice(0, stages.length));

            this.multiplesLookup[idx] = multiplesMatrix;

            let graphObject = {
                name: widget.name,
                data: [{
                    x: xValues,
                    y: yValues,
                    z: zValues,
                    hovertemplate: 'From: %{x}<br>To: %{y}<br>Correlation Score: %{z:.2f}<extra></extra>',
                    type: 'heatmap',
                    colorscale: colorscaleValue,
                    hoverongaps: false
                }],
                layout: {
                    margin: { t: 50, l: 150, r: 0, b: 50 },
                    annotations: [],
                    xaxis: {
                        ticks: '',
                        side: 'bottom'
                    },
                    yaxis: {
                        ticks: '',
                        ticksuffix: ' ',
                        width: 700,
                        height: 700,
                        autosize: false
                    }
                }
            };

            // Annotations
            for (let i = 0; i < yValues.length; i++) {
                for (let j = 0; j < xValues.length; j++) {
                    let currentValue = zValues[i][j];
                    let textColor = currentValue == 0.0 ? 'black' : 'white';
                    let result = {
                        xref: 'x1',
                        yref: 'y1',
                        x: xValues[j],
                        y: yValues[i],
                        text: zValues[i][j],
                        font: { size: 12, color: textColor },
                        showarrow: false
                    };
                    graphObject.layout.annotations.push(result);
                }
            }
            return graphObject;
        }
        catch (err) {
            let graphObject = {
                name: widget.name,
                data: null
            }
            return graphObject;
        }
    }

    plotSystemTrends(widget: any) {
        let data = widget.data;
        let OutsideAirTemp = {
            x: [],
            y: [],
            type: "scatter",
            mode: "lines",
            name: "Outside Air Temp",
            line: {
                fillcolor: "rgba(20 157 115,0.6)",
                color: "rgba(20 157 115,0.6)"
            },
            xaxis: "x",
            yaxis: "y",
            hoverlabel: { namelength: -1 }
        };
        let SatRatDelta = {
            x: [],
            y: [],
            type: "scatter",
            mode: "lines",
            name: "SAT - RAT",
            line: {
                fillcolor: "rgba(160,32,240,0.5)",
                color: "rgba(160,32,240,1)"
            },
            xaxis: "x",
            yaxis: "y",
            hoverlabel: { namelength: -1 }
        }
        let systemTraces: any = [SatRatDelta, OutsideAirTemp];

        let startDateTime = data[0].sat.x[0];
        let lastStage = data[data.length - 1];
        let endDateTime = lastStage.sat.x[lastStage.sat.x.length - 1];

        data.forEach((stage, i) => {
            let delta = stage.delta;
            SatRatDelta.x = SatRatDelta.x.concat(delta.x);
            SatRatDelta.y = SatRatDelta.y.concat(delta.y);

            let oat = stage.oat;
            OutsideAirTemp.x = OutsideAirTemp.x.concat(oat.x);
            OutsideAirTemp.y = OutsideAirTemp.y.concat(oat.y);

            let Rat = stage.rat;
            systemTraces.push({
                name: stage.name,
                x: Rat.x,
                y: Rat.y?.map(val => this.helper.parseNumeric(val, 3)),
                type: "scatter",
                mode: "markers",
                text: [],
                marker: {
                    fillcolor: this.reportsService.getTraceColor(i),
                    color: this.reportsService.getTraceColor(i),
                    line: { color: "transparent" }
                },
                xaxis: "x",
                yaxis: "y",
                hoverlabel: { namelength: -1 }
            })
        })
        return {
            name: widget.name,
            data: systemTraces,
            layout: {
                margin: { b: 120, l: 60, t: 25, r: 120 },
                xaxis: {
                    domain: [0, 1],
                    title: "Time",
                    showgrid: true
                },
                yaxis: {
                    domain: [0, 1],
                    title: "Temperature (deg F)"
                },
                shapes: [
                    {
                        type: "rect",
                        fillcolor: "#75C5F0",
                        line: {
                            color: "#75C5F0"
                        },
                        opacity: 0.3,
                        x0: startDateTime,
                        x1: endDateTime,
                        xref: "x",
                        y0: -8,
                        y1: -20,
                        yref: "y"
                    },
                    {
                        type: "rect",
                        fillcolor: "#006EC7",
                        line: {
                            color: "#006EC7"
                        },
                        opacity: 0.3,
                        x0: startDateTime,
                        x1: endDateTime,
                        xref: "x",
                        y0: -12,
                        y1: -25,
                        yref: "y"
                    },
                    {
                        type: "rect",
                        fillcolor: "#EF9A48",
                        line: {
                            color: "#EF9A48"
                        },
                        opacity: 0.3,
                        x0: startDateTime,
                        x1: endDateTime,
                        xref: "x",
                        y0: 25,
                        y1: 40,
                        yref: "y"
                    },
                    {
                        type: "rect",
                        fillcolor: "#AF251C",
                        line: {
                            color: "#AF251C"
                        },
                        opacity: 0.3,
                        x0: startDateTime,
                        x1: endDateTime,
                        xref: "x",
                        y0: 35,
                        y1: 50,
                        yref: "y"
                    }
                ],
                hovermode: "closest"
            }
        }
    }

    plotEconomizerTrends(widget: any) {
        let data = widget.data;
        var OutsideAirTemp = {
            x: [],
            y: [],
            type: "scatter",
            mode: "lines",
            name: "Outside Air Temp",
            line: {
                fillcolor: "rgba(20 157 115,0.6)",
                color: "rgba(20 157 115,0.6)"
            },
            xaxis: "x",
            yaxis: "y",
            hoverlabel: { namelength: -1 }
        };

        let Rat = {
            x: [],
            y: [],
            type: "scatter",
            mode: "lines",
            name: "RAT",
            line: { color: "rgba(176, 31, 10, 0.8)" },
            xaxis: "x",
            yaxis: "y",
            hoverlabel: { namelength: -1 }
        }

        let Mat = {
            x: [],
            y: [],
            type: "scatter",
            mode: "lines",
            name: "MAT",
            line: { color: "rgba(226, 67, 1, 0.8)" },
            xaxis: "x",
            yaxis: "y",
            hoverlabel: { namelength: -1 }
        }

        let ActualDamperPos = {
            x: [],
            y: [],
            type: "scatter",
            mode: "lines",
            name: "Actual Damper (%)",
            line: { color: "rgb(30 110 169)", dash: 'dot' },
            xaxis: "x",
            yaxis: "y",
            hoverlabel: { namelength: -1 }
        }

        let systemTraces: any = [Rat, Mat, OutsideAirTemp, ActualDamperPos];

        let stageBands = [];
        let stageAnnotations = [];
        data.forEach((stage, i) => {
            let { rat, mat, oat, damperActual, name } = stage;
            let startDateTime = rat.x[0];
            let endDateTime = rat.x[rat.x.length - 1];
            stageBands.push({
                type: 'rect',
                xref: 'x',
                yref: 'paper',
                x0: startDateTime,
                y0: 1.05,
                x1: endDateTime,
                y1: 1.0,
                line: { width: 1, color: 'white' },
                fillcolor: this.reportsService.getTraceColor(i)
            });
            stageAnnotations.push({
                x: dayjs(endDateTime).add(dayjs(startDateTime).diff(endDateTime) / 2, 'millisecond').toISOString(),
                xref: 'x',
                yref: 'paper',
                y: 1.05,
                text: name,
                showarrow: false,
                showlegend: false,
                font: { family: 'Lato, sans-serif', size: 11, color: 'white' },
            })

            Rat.x = Rat.x.concat(rat.x);
            Rat.y = Rat.y.concat(rat.y);

            Mat.x = Mat.x.concat(mat.x);
            Mat.y = Mat.y.concat(mat.y);

            ActualDamperPos.x = ActualDamperPos.x.concat(damperActual.x);
            ActualDamperPos.y = ActualDamperPos.y.concat(damperActual.y);

            OutsideAirTemp.x = OutsideAirTemp.x.concat(oat.x);
            OutsideAirTemp.y = OutsideAirTemp.y.concat(oat.y);
        })
        return {
            name: widget.name,
            data: systemTraces,
            layout: {
                margin: { b: 120, l: 60, t: 25, r: 120 },
                xaxis: {
                    domain: [0, 1],
                    title: "Time",
                    showgrid: true
                },
                yaxis: {
                    domain: [0, 1]
                },
                hovermode: "closest",
                shapes: stageBands,
                annotations: stageAnnotations
            }
        }
    }

    plotTrendChart(widget: any) {
        let data = widget.data;
        let nMultiples = data.multiples.length;

        let multipleAnnotations = data.multiples.map((multiple, i) => {
            return {
                text: multiple.name,
                showarrow: false,
                x: 0,
                xref: i == 0 ? 'x domain' : `x${i + 1} domain`,
                y: 1.06,
                yref: i == 0 ? 'y domain' : `y${i + 1} domain`
            }
        });
        let stageAnnotations = data.stages.map((stage) => {
            return {
                x: dayjs(stage.end).add(dayjs(stage.start).diff(stage.end) / 2, 'millisecond').toISOString(),
                xref: 'x',
                yref: 'paper',
                y: 1.015,
                text: stage.value,
                showarrow: false,
                showlegend: false,
                font: { family: 'Lato, sans-serif', size: 11, color: 'white' },
            }
        });

        return {
            name: widget.name,
            data: data.multiples.map((multiple, i) => {
                return multiple.points.map((point, j) => {
                    return {
                        x: point.x,
                        y: point.y?.map(val => this.helper.parseNumeric(val, 3)),
                        type: "scatter",
                        mode: "lines",
                        name: point.name,
                        xaxis: i == 0 ? 'x' : `x${i + 1}`,
                        yaxis: i == 0 ? 'y' : `y${i + 1}`,
                        line: { color: this.reportsService.getTraceColor(j), width: 1 },
                        hoverlabel: { namelength: -1 }
                    }
                });
            }).flat(1),
            layout: {
                grid: { rows: nMultiples, columns: 1, pattern: 'independent' },
                title: data.name,
                margin: { t: 100, l: 50, r: 50, b: 60 },
                height: 400 * nMultiples,
                xaxis: { showgrid: true },
                font: {
                    family: 'Lato, sans-serif',
                    size: 14,
                    color: '#7f7f7f'
                },
                shapes: data.stages.map((stage, s) => {
                    return {
                        type: 'rect',
                        xref: 'x',
                        yref: 'paper',
                        x0: stage.start,
                        y0: 1.015,
                        x1: stage.end,
                        y1: 1.01,
                        line: { width: 1, color: 'white' },
                        fillcolor: this.reportsService.getTraceColor(s)
                    }
                }),
                annotations: stageAnnotations.concat(multipleAnnotations),
                showlegend: true,
                hovermode: "closest"
            }
        }
    }

    onHover(event: any, idx: any) {
        let multipleMatrix = this.multiplesLookup[idx];
        if (multipleMatrix == null) return;

        let loc = event.points[0].pointIndex;
        let nStages = multipleMatrix[0].length;
        let multiple = multipleMatrix[nStages - 1 - loc[0]][loc[1]];

        this.tooltipGraph[idx] = {
            data: [{
                name: multiple.name,
                x: multiple.x,
                y: multiple.y?.map(val => this.helper.parseNumeric(val, 3)),
                mode: 'markers',
                type: 'scatter'
            }],
            layout: {
                title: {
                    text: multiple.name,
                    font: { family: 'Lato, sans-serif', size: 12 },
                    xref: 'paper',
                    x: 0.05,
                },
                margin: { t: 50, l: 30, r: 30, b: 20 },
            }
        }
    }

    onUnhover(idx) {
        this.tooltipGraph[idx] = null;
    }

    // UTILITY FUNCTIONS

    private groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    private removeBy(arr, subject, predicate) {
        let findIndex = arr.findIndex(a => a[subject] === predicate)

        findIndex !== -1 && arr.splice(findIndex, 1)

        return arr;
    }
}