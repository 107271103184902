import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { StorageService } from 'src/app/shared/services/storage.service';

export interface ReportConfiguration {
    name: string;
    displayName: string;
    summary: string;
    widgets: WidgetType[]
}

export enum WidgetGroup {
    SYSTEM,
    ZONE,
    CUSTOM
}

export interface Widget {
    displayName: string,
    name: string,
    description: string,
    group: WidgetGroup
}

export enum WidgetType {
    ZONE_CONDITIONING_RATES = "ZONE_CONDITIONING_RATES",
    ZONE_CONDITIONING_RATE_CORRELATIONS = "ZONE_CONDITIONING_RATE_CORRELATIONS",
    ZONE_FINAL_AIRFLOW_TEMPS = "ZONE_FINAL_AIRFLOW_TEMPS",
    ZONE_FINAL_AIRFLOW_TEMP_CORRELATIONS = "ZONE_FINAL_AIRFLOW_TEMP_CORRELATIONS",
    SYSTEM_TRENDS = "SYSTEM_TRENDS",
    ECONOMIZER_TRENDS = "ECONOMIZER_TRENDS",
    TREND_CHART_BUILDER = "TREND_CHART_BUILDER"
}

@Injectable({
    providedIn: 'root'
})
export class ReportsService {

    private widgetSource: Subject<any> = new Subject<any>();
    widgetSaved$ = this.widgetSource.asObservable();

    saveWidget(widget: any) {
        this.widgetSource.next(widget);
    }

    reportingApiUrl: string;

    linkShareUrl: string;

    reportConfigurations: ReportConfiguration[] = [
        {
            name: 'systemProfile',
            displayName: 'System Profile Report',
            summary: 'Generates a complete report of system trends such as SAT-RAT deltas',
            widgets: [WidgetType.SYSTEM_TRENDS]
        },
        {
            name: 'fullProfile',
            displayName: 'Full Profile Report',
            summary: 'Generates a complete report of system trends such as SAT-RAT deltas',
            widgets: [WidgetType.SYSTEM_TRENDS, WidgetType.ZONE_CONDITIONING_RATES, WidgetType.ZONE_FINAL_AIRFLOW_TEMPS], 
        },
        {
            name: 'zoneConditioning',
            displayName: 'Zone Conditioning Trends',
            summary: 'Generates a report of zone conditioning performance',
            widgets: [WidgetType.ZONE_CONDITIONING_RATES, WidgetType.ZONE_FINAL_AIRFLOW_TEMPS]
        }
    ];

    widgetTypes: Widget[] = [
        {
            displayName: 'Zone Conditioning Rates',
            name: WidgetType.ZONE_CONDITIONING_RATES,
            description: 'Plots the rate of change of temperatures of zones at each stage in degrees Fahrenheit per minute',
            group: WidgetGroup.ZONE
        },
        {
            displayName: 'Zone Final Airflow Temps',
            name: WidgetType.ZONE_FINAL_AIRFLOW_TEMPS,
            description: 'Plots the air temperatures of zones at the end of each stage',
            group: WidgetGroup.ZONE
        },
        {
            displayName: 'SAT-RAT Delta',
            name: WidgetType.SYSTEM_TRENDS,
            description: 'Plots the supply airflow (SAT) - return airflow (RAT) deltas to diagnose HVAC performance',
            group: WidgetGroup.SYSTEM
        },
        {
            displayName: 'Economizer Trends',
            name: WidgetType.ECONOMIZER_TRENDS,
            description: 'Compares OAO damper modulation with outside-air (OAT) and mixed airflow temp (MAT) to diagnose economizer performance',
            group: WidgetGroup.SYSTEM
        },
        {
            displayName: 'Custom Trend Chart',
            name: WidgetType.TREND_CHART_BUILDER,
            description: 'Custom trend chart',
            group: WidgetGroup.SYSTEM
        }
    ];

    severityMap: any = {
        'SEVERE': {
            order: 0,
            color: '#e24301'
        },
        'INTERNAL_SEVERE': {
            order: 1,
            color: '#e24301'
        },
        'MODERATE': {
            order: 2,
            color: '#F7C325'
        },
        'INTERNAL_MODERATE': {
            order: 3,
            color: '#F7C325'
        },
        'WARN': {
            order: 4,
            color: '#FB9300'
        },
        'INTERNAL_WARN': {
            order: 5,
            color: '#FB9300'
        },
        'LOW': {
            order: 6,
            color: '#0071bc'
        },
        'INTERNAL_LOW': {
            order: 7,
            color: '#0071bc'
        },
        'INFO': {
            order: 8,
            color: '#1AAE9F'
        },
        'INTERNAL_INFO': {
            order: 9,
            color: '#1AAE9F'
        }
    }

    colorPalette: any = [
        '#f3cec9',
        '#cd7eaf',
        '#a262a9',
        '#C25D88',
        '#E95E6F',
        "#FCB34C",
        "#ede15b",
        '#1AAE9F',
        "#80C298",
        "#75ACA5",
        '#92CA4B',
        '#77e0c6',
        '#77c5e0',
        '#7791e0',
        '#9277e0',
        '#c677e0',
        '#e077c6',
    ]
    
    activeTestSource: Subject<string> = new Subject<string>();
    activeTestSource$ = this.activeTestSource.asObservable();

    constructor(
        private http: HttpClient,
        private configService: ConfigurationService,
        private storageService: StorageService
    ) {
        this.reportingApiUrl = `${this.configService.get('buildingAnalyticsUrl')}/auto-commissioning/reports`;
        this.linkShareUrl = `${this.configService.get('reportingUrl')}${this.configService.get('publicUrl')}`;
    }

    setHttpParams(params) {
        let httpParams = new HttpParams();
        if (params) {

            Object.keys(params).forEach((key, index) => {
                if (params[key] instanceof Object) {
                    httpParams = httpParams.append(key, JSON.stringify(params[key]));
                } else {
                    httpParams = httpParams.append(key, params[key]);
                }
            });
        }
        return httpParams;
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error);
    }

    private get jsonTypeHeaders() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.storageService.bearerToken}`
            })
        };
    }

    private get anonymousHeaders() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            })
        };
    }

    getReportById(reportId: string): Observable<any> {
        return this.http.get(this.reportingApiUrl + `/${reportId}`, this.jsonTypeHeaders).pipe(catchError(this.handleError));
    }

    getReportByLinkShareId(linkShareId: string): Observable<any> {
        return this.http.get(this.reportingApiUrl + `/shared/${linkShareId}`, this.anonymousHeaders).pipe(catchError(this.handleError));
    }

    updateReportLinkSharing(reportId: string, isSharingEnabled: boolean): Observable<any> {
        return this.http.put(this.reportingApiUrl + `/${reportId}?linkSharingEnabled=${isSharingEnabled}`, null, this.jsonTypeHeaders).pipe(catchError(this.handleError));
    }

    getTraceColors() {
        return this.colorPalette;
    }

    getTraceColor(i) {
        return this.colorPalette[i%this.colorPalette.length];
    }
}
