import { Injectable } from '@angular/core';
import { StorageKeys } from '../enums/StorageKeys.enum';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  /**
   * Clear local and session storage item for the key supplied
   * @param key Key to clear
   */
  clearItem(key: string) {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  }

  /**
   * Get the current bearer-token
   */
  get bearerToken() {
    return localStorage.getItem(StorageKeys.BEARER_TOKEN);
  }

  /**
   * Store the user's email address in local storage
   */
  set emailAddress(email: string) {
    localStorage.setItem(StorageKeys.EMAIL_ADDRESS, email);
  }

  /**
   * Get the current user's email address
   */
  get emailAddress() {
    return localStorage.getItem(StorageKeys.EMAIL_ADDRESS);
  }

  /**
   * Store the user's ID in local storage
   */
  set userId(userId: string) {
    localStorage.setItem(StorageKeys.USER_ID, userId);
  }

  /**
   * Get the current user's ID
   */
  get userId() {
    return localStorage.getItem(StorageKeys.USER_ID);
  }

  /**
   * Store the bearer token in local storage
   */
  set bearerToken(token: string) {
    localStorage.setItem(StorageKeys.BEARER_TOKEN, token);
  }

  /**
   * Get the current route
   */
  get route() {
    return sessionStorage.getItem(StorageKeys.ROUTE);
  }

  /**
   * Set the current route in local storage
   */
  set route(route: string) {
    sessionStorage.setItem(StorageKeys.ROUTE, route);
  }

}
