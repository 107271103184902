import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

@Injectable({
  providedIn: 'root'
})
export class Helper {
  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  removeBy(arr: any[], subject: string, predicate: any) {
    let findIndex = arr.findIndex(a => a[subject] === predicate)

    findIndex !== -1 && arr.splice(findIndex, 1)

    return arr;
  }

  isBlank(value: string) {
    return value == null || value.trim() === '';
  }

  /**
   * Capitalize the first letter of each word in a string with the below exceptions
   * * 'ui' or 'Ui' will become 'UI'
   * @param str string to capitalize
   * @returns capitalized string
   */
  capitalize(str: string) {

    let capitalizedString = str.toLowerCase().split(' ').map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase()).join(' ');

    // Replace Ui with UI
    capitalizedString = capitalizedString.replace('Ui', 'UI');
    return capitalizedString;

  }

  get randomId() {
    return Math.floor((Math.random() * 1000000) + 1);
  }

  formatException(error: any): string {
    if (typeof error?.error?.error == 'string') {
      return error.error.error;
    }
    return null;
  }

  parseNumeric(val: any, precision: number) {
    if (val == Infinity) return NaN;
    return parseFloat(val?.toPrecision(precision));
  }

  prettifyDatetime(datetimeUTC: string) {
    return dayjs(datetimeUTC).local().format('DD MMM, YYYY | hh:mm A');
  }
}
