import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError, first, map, mergeMap, tap } from 'rxjs/operators';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { HDict, HGrid, HMarker, HRef, Kind, ZincReader } from '@j2inn/haystack-core';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ConfigurationService } from './configuration.service';
import { Site } from 'src/app/auto-cx/models/Site.model';
import { BuildingEntities } from 'src/app/auto-cx/models/BuildingEntities.model';
import { Entity } from 'src/app/auto-cx/models/Entity.model';

@Injectable({
  providedIn: 'root'
})
export class SiloService {

  private reloadEntitiesSource: Subject<string> = new Subject<string>();
  reloadEntities$ = this.reloadEntitiesSource.asObservable();

  siloUrl: string;

  constructor(
    private http: HttpClient,
    private configService: ConfigurationService,
    private storage: StorageService
  ) {
    this.siloUrl = this.configService.get('siloUrl');
  }

  setHttpParams(params) {
    let httpParams = new HttpParams();
    if (params) {

      Object.keys(params).forEach((key, index) => {
        if (params[key] instanceof Object) {
          httpParams = httpParams.append(key, JSON.stringify(params[key]));
        } else {
          httpParams = httpParams.append(key, params[key]);
        }
      });
    }
    return httpParams;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error);
  }

  get authOnlyHeaders() {
    return new HttpHeaders({
      'Content-Type': 'text/zinc',
      'Accept': 'text/zinc',
      'Authorization': `Bearer ${this.storage.bearerToken}`
    });
  }

  findCCUDevicesForSites(siteIds: string[]): Observable<Entity[]> {
    const sitesQuery = siteIds.map(siteId => `siteRef==@${siteId}`).join(' or ');
    return this.filterQuery(`ccu and device and (${sitesQuery})`).pipe(
      mergeMap(entities => this.mapEntities(entities))
    )
  }

  findByIds(entityIds: string[]): Observable<Entity[]> {
    return this.readQuery(entityIds).pipe(
      mergeMap(entities => this.mapEntities(entities))
    )
  }

  filterQuery(query: string) {
    const filterGrid = new HGrid({ columns: [{ name: 'filter' }], rows: [{ filter: query }] });
    return this.http.post(`${this.siloUrl}/v2/read`, filterGrid.toZinc(), { headers: this.authOnlyHeaders, responseType: 'text' }).pipe(
      map(result => this.readZinc(result)),
      catchError(this.handleError)
    );
  }

  readQuery(entityIds: string[]) {
    const readGrid = new HGrid({ 
      columns: [{ name: 'id' }], 
      rows: entityIds.map(entityId => new HDict({ id: HRef.make(entityId) }))
    });
    return this.http.post(`${this.siloUrl}/v2/read`, readGrid.toZinc(), { headers: this.authOnlyHeaders, responseType: 'text' }).pipe(
      map(result => this.readZinc(result)),
      catchError(this.handleError)
    );
  }

  navIdQuery(navId: string) {
    const navGrid = new HGrid({ columns: [{ name: 'navId' }], rows: [new HDict({ navId: HRef.make(navId) })] });
    return this.http.post(`${this.siloUrl}/v2/nav`, navGrid.toZinc(), { headers: this.authOnlyHeaders, responseType: 'text' }).pipe(
      map(result => this.readZinc(result)),
      catchError(this.handleError)
    );
  }

  private readZinc(zinc: string) {
    return ZincReader.readValue(zinc.replace(/\\/g, "\\\\"));
  }

  public getSites(): Observable<Site[]> {
    return this.filterQuery('site').pipe(mergeMap((entities) => this.mapSites(entities)))
  }

  public getBuildingEntities(siteId: string): Observable<BuildingEntities> {
    return this.filterQuery(`siteRef==@${siteId} and ((ccu) or (equip) or (floor) or (room) or (device))`).pipe(
      mergeMap((entities) => this.mapEntities(entities)),
      map((entities) => {
        return {
          ccuDevices: entities.filter(e => e.tags.includes("ccu")),
          systemEquips: entities.filter(e => (e.tags.includes("system") || e.tags.includes("oao")) && e.tags.includes("equip")),
          buildingTuner: entities.filter(e => e.tags.includes("tuner") && e.tags.includes("equip"))[0],
          diagEquips: entities.filter(e => e.tags.includes("diag") && e.tags.includes("equip")),
          zoneEquips: entities.filter(e => e.tags.includes("zone") && e.tags.includes("equip")),
          zones: entities.filter(e => e.tags.includes("room")),
          floors: entities.filter(e => e.tags.includes("floor")),
          devices: entities.filter(e => e.tags.includes("devices") && !e.tags.includes("ccu"))
        }
      })
    )
  }

  private mapSites(entities): Observable<Site[]> {
    return of(entities.map(entity => {
      return {
        id: (entity.get('id') as HRef).dis,
        name: entity.get('dis')?.toString(),
        address: `${entity.get('geoAddr')}, ${entity.get('geoCity')}, ${entity.get('geoState')}, ${entity.get('geoCountry')} - ${entity.get('geoPostalCode')}`,
        timeZone: entity.get('tz')?.toString(),
        organization: entity.get('organization')?.toString(),
        facilityManagerEmail: entity.get('fmEmail')?.toString()
      }
    }))
  }

  public getTagsForSite(siteId: string) {
    return this.filterQuery(`siteRef==@${siteId} and his and point`).pipe(
      mergeMap((entities) => this.mapTags(entities))
    );
  }

  public getPointsByQuery(query: string) {
    return this.filterQuery(`point and ${query}`).pipe(
      mergeMap((entities) => this.mapEntities(entities))
    );
  }

  mapTags(entities: any) {
    return of(Array.from(new Set(entities.map(entity => {
      return this.getTagsFromEntity(entity);
    }).flat().sort())));
  }

  private mapEntities(entities: HDict[]): Observable<Entity[]> {
    return of(entities.map(entity => {
      return {
        id: (entity.get('id') as HRef).dis,
        type: this.getEntityType(entity),
        name: entity.get('dis').toString(),
        tags: this.getMarkerTagsFromEntity(entity),
        ahuRef: (entity.get('ahuRef') as HRef)?.dis,
        gatewayRef: (entity.get('gatewayRef') as HRef)?.dis,
        siteRef: (entity.get('siteRef') as HRef)?.dis,
        floorRef: (entity.get('floorRef') as HRef)?.dis,
        roomRef: (entity.get('roomRef') as HRef)?.dis
      }
    }).sort(this.compare));
  }

  wasCreatedByApplication(entity: any) {
    const createdBy = entity.get('createdByApplication')?.toString();
    return createdBy === 'SITE_MANAGER';
  }

  /**
   * Get all of the marker tags from an entiity
   * @param entity 
   * @returns array of tags
   */
  getMarkerTagsFromEntity(entity: any) {
    const tags = [];
    entity.keys.forEach(key => {
      if (this.isMarker(entity.get(key))) {
        tags.push(key);
      }
    });
    return tags;
  }

  /**
   * Get all of the tags from an entiity
   * @param entity 
   * @returns array of tags
   */
  getTagsFromEntity(entity: any) {
    return entity.keys;
  }

  /**
   * Determine if the value is a marker tag
   * @param val Value to check
   * @returns true if marker tag
   */
  private isMarker(val) {
    return val.isKind(Kind.Marker);
  }

  /**
   * Sort rows based on entity type
   * @param entity
   * @returns number representing the position of the element
   */
  private getSortOrder(entity: any) {
    if (this.getEntityType(entity) === 'schedule') {
      return 1;
    } else if (this.getEntityType(entity) === 'floor') {
      return 2;
    } else if (this.getEntityType(entity) === 'zone') {
      return 3;
    } else if (this.getEntityType(entity) === 'equip') {
      return 4;
    } else if (this.getEntityType(entity) === 'point') {
      return 5;
    } else {
      return 6;
    }
  }

  private getEntityType(entity: HDict) {
    if (entity.has('floor')) {
      return 'floor';
    } else if (entity.has('room')) {
      return 'zone';
    } else if (entity.has('equip')) {
      return 'equip';
    } else if (entity.has('point')) {
      return 'point';
    } else if (entity.has('schedule')) {
      return 'schedule';
    } else if (entity.has('device')) {
      return 'device';
    } else {
      return null;
    }
  }

  reloadEntities(ref: string) {
    this.reloadEntitiesSource.next(ref);
  }

  compare(a, b) {
    if (a.sortOrder < b.sortOrder) {
      return -1;
    }
    if (a.sortOrder > b.sortOrder) {
      return 1;
    }
    return 0;
  }

}
