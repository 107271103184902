import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, tap } from 'rxjs/operators';
import { BaseWrapperDirective } from '../../../classes/base-wrapper';
import { RouteService } from '../../../services/route.service';
import { SearchService } from '../../../services/search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html'
})
export class SearchComponent extends BaseWrapperDirective implements OnInit {

  formControl: UntypedFormControl = new UntypedFormControl('');
  @Input() title: string;
  @Input() disabled: boolean;
  @Input() limitedSearch: boolean;
  placeholder;

  constructor(private searchService: SearchService,
              private routeService: RouteService) {
    super();
  }

  ngOnInit(): void {

    this.placeholder = this.title || "Search";

    if (this.disabled) {
      this.formControl.disable();
    }

    if (!this.limitedSearch) {

      // This is your standard table search filter
      this.subs.sink = this.formControl.valueChanges.pipe(
        debounceTime(200),
        tap((value) => this.searchService.search(value))
      ).subscribe();

    } else {

      // If the UI requires a standalone search box
      this.subs.sink = this.formControl.valueChanges.pipe(
        debounceTime(200),
        tap((value) => this.searchService.limitedScopeSearch(value))
      ).subscribe();

    }

    this.subs.sink = this.routeService.route$.pipe(
      tap(() => this.formControl.reset())
    ).subscribe();

  }

}
