export const environment = {
  production: false,
  caretakerUrl: "https://caretaker.dev.service.75f.io/api/v1",
  siloUrl: "https://silo.dev.service.75f.io",
  buildingAnalyticsUrl: "https://bas.dev.service.75f.io",
  reportingUrl: "https://auto-cx-dev.75f.io",
  publicUrl: "/reports/public",
  messagingUrl: "https://messaging.dev.service.75f.io/api/v1",
  gatekeeperUrl: "https://gatekeeper.dev.service.75f.io",
  appSecret: "i4V6iNfmeX1PN0IsafOO8uG1f2i2FKMx"
};
