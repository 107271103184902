
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import dayjs from 'dayjs';
import { DaterangepickerComponent, DaterangepickerDirective } from 'ngx-daterangepicker-material';

export interface DatetimeRange {
    startDatetime: dayjs.Dayjs,
    stopDatetime: dayjs.Dayjs
}

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss']
})
export class DatetimePickerComponent {
    datetimeRange: DatetimeRange;

    predefinedDateRanges: any = null;

    @Input() defaultStartDatetime: dayjs.Dayjs;
    @Input() defaultStopDatetime: dayjs.Dayjs;
    @Input() locale: any = { applyLabel: 'ok', format: 'DD MMM YYYY' };
    @Input() tooltipText: string;
    @Input() singleDatePicker: boolean = false;
    @Input() linkedCalendars: boolean = false;
    @Input() autoApply: boolean = false;
    @Input() timePicker: boolean = true;
    @Input() minDate: dayjs.Dayjs;
    @Input() placeholder: string;

    @Output() selectionChange = new EventEmitter<any>();
    
    @ViewChild(DaterangepickerDirective, { static: false }) dateRangePicker: DaterangepickerDirective;
    @ViewChild(DaterangepickerComponent) dateRangePickerComponent: DaterangepickerComponent;

    ngOnInit() {
        this.datetimeRange = {
            startDatetime: this.defaultStartDatetime,
            stopDatetime: this.defaultStopDatetime
        }
        if (!this.singleDatePicker) {
            this.predefinedDateRanges = {
                'Today': [dayjs().startOf('day'), dayjs().endOf('day')],
                'Last 3 Days': [dayjs().subtract(2, 'days'), dayjs().endOf('day')],
                'Last Week': [dayjs().subtract(6, 'days').startOf('day'), dayjs().endOf('day')],
                'Last Month': [dayjs().subtract(1, 'month').add(1, 'days').startOf('day'), dayjs().endOf('day')],
                'Last 6 Months': [dayjs().subtract(6, 'month').add(1, 'days').startOf('day'), dayjs().endOf('day')],
                'Last Year': [dayjs().subtract(12, 'month').add(1, 'days').startOf('day'), dayjs().endOf('day')]
            };
        }
    }

    onDateSelected() {
        this.selectionChange.emit(this.datetimeRange);
    }

    handleOpenDateRangePicker() {
        this.dateRangePicker.open();
    }
}
