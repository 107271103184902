import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AutoCxService } from '../../services/auto-cx-service';

export interface MessageContents {
  name: string
  items: any[]
}

@Component({
  selector: 'app-auto-cx-confirm',
  templateUrl: './auto-cx-confirm.component.html',
  styleUrls: ['./auto-cx-confirm.component.scss']
})
export class AutoCxConfirmationComponent implements OnInit {

  title: string;
  message: string;
  contents: MessageContents[];
  cancel: boolean;
  confirmTrueText: string;
  confirmFalseText: string;

  constructor(
    private autoCxService: AutoCxService,
    public dialogRef: MatDialogRef<AutoCxConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.title = this.data.title || '';
    this.message = this.data.message || '';
    this.contents = this.data.contents;
    this.cancel = this.data.cancel ?? false;
    this.confirmTrueText = this.data.confirmTrueText || 'Yes';
    this.confirmFalseText = this.data.confirmFalseText || 'No';
  }

  confirm(response: boolean) {
    this.autoCxService.confirm.next(response);
    this.dialogRef.close();
  }
}
