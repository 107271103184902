import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AutoCxBlockly } from 'src/app/auto-cx/components/auto-cx-blockly/auto-cx-blockly.component';
import { AutoCxBuilderComponent } from 'src/app/auto-cx/components/auto-cx-builder/auto-cx-builder.component';
import { CustomTrendChartComponent } from 'src/app/auto-cx/components/auto-cx-dialogs/custom-trend-chart/custom-trend-chart.component';
import { RouteService } from './route.service';
import { AutoCxConfirmationComponent, MessageContents } from '../../auto-cx/components/auto-cx-confirm/auto-cx-confirm.component';
import { AutoCxReportViewComponent } from 'src/app/auto-cx/components/auto-cx-reports/auto-cx-report-view/auto-cx-report-view.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog, private routeService: RouteService) { }
  
  createCustomBlock(block: any, newBlock: boolean, editMode: boolean) {
    this.dialog.open(AutoCxBlockly, {
      height: '90%',
      width: '80%',
      disableClose: false,
      data: { block, newBlock, editMode }
    });
  }

  openBuilderInFlowViewMode(flowId: string) {
    this.dialog.open(AutoCxBuilderComponent, {
      height: '90%',
      width: '80%',
      disableClose: false,
      data: { flowId, viewMode: true }
    });
  }

  openReport(testId: string) {
    this.dialog.open(AutoCxReportViewComponent, {
      width: '80%',
      disableClose: false,
      data: { testId }
    });
  }

  confirmation(title: string, message: string, contents: MessageContents[], cancel: boolean, confirmTrueText?: string, confirmFalseText?: string, height?: string, width?: string) {
    this.dialog.open(AutoCxConfirmationComponent, {
      height: height || '200px',
      width: width || '300px',
      disableClose: false,
      data: { title, message, contents, cancel, confirmTrueText, confirmFalseText }
    });
  }

  openCustomTrendChartBuilder(payload: any) {
    this.dialog.open(CustomTrendChartComponent, {
      height: '70%',
      width: '80%',
      disableClose: true,
      data: payload
    });
  }
}
