import { ViewEncapsulation, Component, ElementRef, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

import { AutoCxService } from '../../services/auto-cx-service';

import * as Blockly from 'blockly';

import { ToastService } from 'src/app/shared/services/toast.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseWrapperDirective } from 'src/app/shared/classes/base-wrapper';
import { toolboxData } from '@75f/blockly-lib';
import { Helper } from 'src/app/shared/helpers/helper';
import { BlockStatistics } from '../../models/BlockStatistics.model';

@Component({
    selector: 'app-auto-cx-blockly',
    templateUrl: 'auto-cx-blockly.component.html',
    styleUrls: ['auto-cx-blockly.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AutoCxBlockly extends BaseWrapperDirective implements OnInit {
    block: any;
    newBlock: boolean;
    editMode: boolean = true;

    blockManagementGroup: FormGroup;

    workspaceSvg: Blockly.WorkspaceSvg;
    blocklyWorkspaceInit: boolean = false;
    
    displayIcons = [
        {
            color: 'rgb(223, 124, 3)',
            icon: 'fas fa-fire'
        },
        {
            color: 'dodgerblue',
            icon: 'far fa-snowflake'
        },
        {
            color: 'rgb(25 205 72)',
            icon: 'fas fa-wind'
        },
        {
            color: 'rgb(223, 124, 3)',
            icon: 'fas fa-temperature-high'
        },
        {
            color: 'dodgerblue',
            icon: 'fas fa-temperature-low'
        },
        {
            color: 'rgb(25 197 205)',
            icon: 'far fa-clock'
        },
        {
            color: 'rgb(25 197 205)',
            icon: 'fas fa-server'
        },
        {
            color: 'rgb(233 160 27)',
            icon: 'fas fa-bolt'
        },
        {
            color: 'rgb(25 82 205)',
            icon: 'fas fa-sliders'
        },
        {
            color: '#ffc800',
            icon: 'far fa-lightbulb'
        },
        {
            color: 'rgb(221 88 108)',
            icon: 'fas fa-gears'
        }
    ];

    blockIconSetting: any = {
        color: 'rgb(221 88 108)',
        icon: 'fas fa-gears'
    };

    isValidBlocklyLogic: boolean = false;
    blockStatistics: BlockStatistics;
    blockErrors: any = [];

    // Toolbox element
    @ViewChild('toolbox') toolbox: ElementRef;
    constructor(
        private formBuilder: FormBuilder,
        private autoCxService: AutoCxService,
        private toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AutoCxBlockly>,
        private helper: Helper
    ) {
        super();
        this.block = data.block;
        this.newBlock = data.newBlock;
        this.editMode = data.editMode;
    }

    ngOnInit() {
        this.buildForms();
        this.createBlocklyInstance(false, false, "start");
    }

    buildForms() {
        this.blockManagementGroup = this.formBuilder.group({
            opCode: new FormControl(),
            blockName: new FormControl(),
            blockDescription: new FormControl(),
            blockType: new FormControl(),
            blockData: new FormControl(),
            blockNumInputs: new FormControl(),
            blockNumOutputs: new FormControl(),
            blockIsPublished: new FormControl()
        });
    }

    createBlocklyInstance(rtl, horizontalLayout, position) {
        let workspaceOptions: any = {
            comments: true,
            collapse: true,
            disable: true,
            maxBlocks: Infinity,
            media: null,
            oneBasedIndex: true,
            scrollbars: true,
            trashcan: true,
            zoom: {
                controls: true,
                wheel: false,
                startScale: 1.0,
                maxScale: 4,
                minScale: 0.25,
                scaleSpeed: 1.1
            },
            toolbox: toolboxData,
            toolboxPosition: position,
            rtl: rtl,
            horizontalLayout: horizontalLayout,
            readOnly: !this.editMode
        };

        if (!this.workspaceSvg) {
            this.workspaceSvg = Blockly.inject("blocklyContainer", workspaceOptions);

            this.workspaceSvg.addChangeListener((_) => this.validateBlocklyLogic());

            setTimeout(() => {
                Blockly.svgResize(this.workspaceSvg);
            }, 10);
        }

        if (!this.newBlock) { // if this is an existing block's dialog, populate the form fields and Blockly workspace with xml
            if (this.block) {
                this.blockManagementGroup.controls["opCode"].setValue(this.block.opCode);
                this.blockManagementGroup.controls["blockName"].setValue(this.block.name);
                this.blockManagementGroup.controls["blockType"].setValue(this.block.blockType);
                this.blockManagementGroup.controls["blockDescription"].setValue(this.block.description);
                this.blockManagementGroup.controls["blockNumInputs"].setValue(this.block.numInputs);
                this.blockManagementGroup.controls["blockNumOutputs"].setValue(this.block.numOutputs);
                this.blockManagementGroup.controls["blockIsPublished"].setValue(this.block.isPublished);

                this.setBlockIconSetting(this.block.presentationData);

                // if xml data exists for this node, populate the Blockly workspace
                if (this.block.executionData.xml) {
                    let dom = Blockly.Xml.textToDom(this.block.executionData.xml);
                    Blockly.Xml.domToWorkspace(dom, Blockly.getMainWorkspace());
                }
            }
        }
    }

    validateBlocklyLogic() {
        try {
            this.blockStatistics = this.autoCxService.evaluateWorkspace(Blockly.getMainWorkspace());
            let errors = [];

            if (!this.blockStatistics.isFanLoopEnabled) {
                errors.push({
                    message: "Fan loop must be enabled for system-level tests",
                    type: "ERROR"
                })
            }
            if (this.blockStatistics.isTempOutOfBounds) {
                errors.push({
                    message: "Temperature Setpoints must be within 50 and 120 °F",
                    type: "ERROR"
                })
            }
            if (this.totalBlockRuntime == 0) {
                errors.push({
                    message: "Block cannot have zero runtime",
                    type: "ERROR"
                })
            }
            this.blockErrors = [...errors];
        }
        catch (error) {
            this.isValidBlocklyLogic = false;
            return;
        }
        this.isValidBlocklyLogic = true;
    }

    get totalBlockRuntime() {
        return this.blockStatistics.systemCommandDuration + this.blockStatistics.zoneCommandDuration + this.blockStatistics.totalDelay;
    }

    /**
     * A block can only be submitted iff:
     * 1) Any of the block parameters have been updated (name, description, meta-data)
     * 2) The Blockly logic has no errors
     */
    get isValid() {
        return !(this.blockManagementGroup.controls["blockName"].value === this.block?.name &&
            this.blockManagementGroup.controls["blockDescription"].value === this.block?.description &&
            this.block?.data === this.blockXml) &&
            this.isValidBlocklyLogic &&
            this.blockErrors.length == 0 &&
            !this.helper.isBlank(this.blockManagementGroup.controls["blockName"].value) &&
            !this.helper.isBlank(this.blockManagementGroup.controls["blockDescription"].value)
    }

    get blockXml() {
        let dom = Blockly.Xml.workspaceToDom(Blockly.getMainWorkspace());
        return Blockly.Xml.domToText(dom);
    }

    saveAsNewBlock() {
        const xml_text = this.blockXml;
        const newBlock = {
            name: this.blockManagementGroup.controls["blockName"].value,
            blockType: "Control",
            executionData: { xml : xml_text },
            description: this.blockManagementGroup.controls["blockDescription"].value,
            presentationData: this.buildPresentationData(),
            isPublished: false,
            numInputs: 1,
            numOutputs: 1,
        }
        this.subs.sink = this.autoCxService.createBlock(newBlock).subscribe(() => {
            this.autoCxService.loadBlockLibrary.next();
            this.toastService.success("Successfully created block!");
            this.close();
        }, (error) => {
            this.toastService.error({ message: "Failed to create block" });
            console.error(error);
        });
    }

    updateBlock() {
        const xml_text = this.blockXml;
        const updateBlock = {
            opCode: this.blockManagementGroup.controls["opCode"].value,
            name: this.blockManagementGroup.controls["blockName"].value,
            blockType: this.blockManagementGroup.controls["blockType"].value,
            executionData: { xml : xml_text },
            description: this.blockManagementGroup.controls["blockDescription"].value,
            presentationData: this.buildPresentationData(),
            isPublished: this.blockManagementGroup.controls["blockIsPublished"].value,
            numInputs: this.blockManagementGroup.controls["blockNumInputs"].value,
            numOutputs: this.blockManagementGroup.controls["blockNumOutputs"].value,
        }
        this.subs.sink = this.autoCxService.updateBlock(updateBlock).subscribe(() => {
            this.autoCxService.loadBlockLibrary.next();
            this.toastService.success("Successfully updated block!");
            this.close();
        }, (error) => {
            this.toastService.error({ message: "Failed to update block" });
            console.error(error);
        });
    }

    private buildPresentationData() {
        return {
            icon: this.blockIconSetting.icon,
            color: this.blockIconSetting.color
        }
    }

    private setBlockIconSetting(presentationData: any) {
        this.blockIconSetting = {
            icon: presentationData.icon,
            color: presentationData.color
        }
    }

    /**
     * Evaluates whether or not "Save" action should be enabled for this block.
     */
    isSaveAllowed() {
        return this.editMode && !this.newBlock;
    }

    close() {
        this.dialogRef.close();
    }
}
