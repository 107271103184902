import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { BaseWrapperDirective } from 'src/app/shared/classes/base-wrapper';
import { MatSort } from '@angular/material/sort';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { AutoCxService } from 'src/app/auto-cx/services/auto-cx-service';
import { ReportsService } from 'src/app/auto-cx/services/reports-service';
import { SearchService } from 'src/app/shared/services/search.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['table.styles.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableComponent extends BaseWrapperDirective implements OnInit {

  @Input() data: any[] = [];

  columns: Array<any>;
  displayedColumns: Array<any>;
  dataSource: MatTableDataSource<any>;
  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  excludedColumns: string[] = ['metadata'];
  
  constructor(
    public autoCxService: AutoCxService,
    public dialogService: DialogService,
    public authService: AuthenticationService,
    public reportsService: ReportsService,
    public searchService: SearchService,
    public toastService: ToastService
  ) {
    super();
  }

  ngOnInit() {
    this.loadTableData(this.data);
  }

  // Ignores leading whitespace and casing for sorting purposes only
  customSortingDataAccessor = (data: any, sortHeaderId: string): string => {
    if (typeof data[sortHeaderId] === 'string') {
      return data[sortHeaderId]?.toLocaleLowerCase().trim();
    } else if (typeof data[sortHeaderId] === 'boolean') {
      return data[sortHeaderId] ? '1' : '0';
    } else {
      return data[sortHeaderId]?.trim();
    }
  }

  onSortChanged() {
    this.dataSource.sort = this.sort;
  }

  loadTableData(items: any[]) {
    // Get list of columns by gathering unique keys of objects found in DATA.
    const _columns = items.reduce((columns, row) => {
      return [...columns, ...Object.keys(row)];
    }, []).reduce((columns, column) => {
      return columns.includes(column) ? columns : [...columns, column];
    }, []);

    this.columns = _columns.map((column) => {
      return {
        columnDef: column,
        header: column,
        cell: (element) => element[column],
        sortingDisabled: false
      };
    });

    this.displayedColumns = this.columns.map(c => c.columnDef);
    
    let dataSource: MatTableDataSource<any> = new MatTableDataSource(items);
    dataSource.paginator = this.paginator;
    dataSource.sort = this.sort;
    this.dataSource = dataSource;
    this.dataSource.sortingDataAccessor = this.customSortingDataAccessor;
  }
}
