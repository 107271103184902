import { Directive } from '@angular/core';
import { Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appMustTrim]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: MustTrimValidatorDirective,
    multi: true
  }]
})
export class MustTrimValidatorDirective implements Validator {

  constructor() { }

  validate(control: any): any {
    
    if ((control.value == null) || (control.value.length == 0)) {
      return { message: 'Cannot be empty' };
    }

    if (control.value && control.value.length !== control.value.trim().length) {
      return { message: 'Cannot begin or end with empty whitespace' };
    }

    return null;
    
  }

}
