import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {

  @Input() options: string[] = [];
  @Input() placeholder: string;
  @Input() initialValue: string;

  @Output() valueChanged = new EventEmitter<any>();

  formControl = new FormControl('');
  filteredOptions: Observable<string[]>;

  ngOnInit() {

    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || ''))
    );

    this.formControl.valueChanges.pipe(
      debounceTime(250),
      tap((value) => this.valueChanged.emit(value))
    ).subscribe();
    
    if (this.initialValue) {
      this.formControl.setValue(this.initialValue);
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options?.filter(option => option.toLowerCase().includes(filterValue));
  }

  clear() {
    this.formControl.reset();
  }

  get canClear() {
    return this.formControl.value;
  }

}
