import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})

export class LoaderComponent implements OnInit {

  @Input() theme;

  loaderTheme;
  count;

  ngOnInit() {
    this.loaderTheme = this.theme || {
      height: '30px',
      margin: '12px 0',
      'background-color': '#eff0f1'
    };
    this.count = this.theme?.count || 10;
  }

}
