import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HDict, HGrid, HRef, HStr, Kind, ZincReader } from '@j2inn/haystack-core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private errorSource: Subject<void> = new Subject<void>();
  error$ = this.errorSource.asObservable();

  siloUrl: string;

  constructor(public http: HttpClient,
    private configService: ConfigurationService,
    private storage: StorageService) {
    this.siloUrl = this.configService.get('siloUrl');
  }

  /**
   * Get the logged in user object
   * @returns Logged in user info
   */
  getUser() {
    const url = `${this.configService.get('caretakerUrl')}/token/userinfo`;
    return this.get(url, { headers: this.jsonTypeHeaders });
  }

  navIdQuery(navId: string) {
    const navGrid = new HGrid({ columns: [{ name: 'navId' }], rows: [new HDict({ navId: HRef.make(navId) })] });
    return this.http.post(`${this.siloUrl}/v2/nav`, navGrid.toZinc(), { headers: this.zincHeaders, responseType: 'text' }).pipe(
      map(result => this.readZinc(result)),
      catchError(this.handleHttpError)
    );
  }

  public getEntities(navId: string) {
    return this.navIdQuery(`@${navId}`).pipe(
      mergeMap((entities) => this.mapEntities(entities))
    );
  }

  private mapEntities(entities): Observable<any> {
    return of(entities.map(entity => this.mapEntity(entity)).sort(this.compare));
  }

  private mapEntity(dict: HDict): any {
    let entity: any = {
      id: (dict.get('id') as HRef).dis,
      type: this.getEntityType(dict),
      name: dict.get('dis').toString(),
      tags: this.getTagsFromEntity(dict),
      siteRef: (dict.get('siteRef') as HRef)?.dis,
      floorRef: (dict.get('floorRef') as HRef)?.dis,
      roomRef: (dict.get('roomRef') as HRef)?.dis,
      sortOrder: this.getSortOrder(dict)
    }
    if (entity.type == 'equip') {
      entity.sourceId = (dict.get('sourceModel') as HStr)?.value
    }
    return entity;
  }

  private getSortOrder(entity: any) {
    if (this.getEntityType(entity) === 'schedule') {
      return 1;
    } else if (this.getEntityType(entity) === 'floor') {
      return 2;
    } else if (this.getEntityType(entity) === 'zone') {
      return 3;
    } else if (this.getEntityType(entity) === 'equip') {
      return 4;
    } else if (this.getEntityType(entity) === 'point') {
      return 5;
    } else {
      return 6;
    }
  }

  getTagsFromEntity(entity: any) {
    const tags = [];
    entity.keys.forEach(key => {
      if (this.isMarker(entity.get(key))) {
        tags.push(key);
      }
    });
    return tags;
  }

  private isMarker(val) {
    return val.isKind(Kind.Marker);
  }

  private getEntityType(entity: HDict) {
    if (entity.has('floor')) {
      return 'floor';
    } else if (entity.has('room')) {
      return 'zone';
    } else if (entity.has('equip')) {
      return 'equip';
    } else if (entity.has('point')) {
      return 'point';
    } else if (entity.has('schedule')) {
      return 'schedule';
    } else if (entity.has('device')) {
      return 'device';
    } else {
      return null;
    }
  }

  private readZinc(zinc: string) {
    return ZincReader.readValue(zinc.replace(/\\/g, "\\\\"));
  }

  get baseUrl() {
    return this.configService.get('buildingAnalyticsUrl');
  }

  get jsonTypeHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.storage.bearerToken}`
    });
  }

  get authOnlyHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.storage.bearerToken}`
    });
  }

  get zincHeaders() {
    return new HttpHeaders({
      'Content-Type': 'text/zinc',
      'Accept': 'text/zinc',
      'Authorization': `Bearer ${this.storage.bearerToken}`
    });
  }

  get(url: string, options?: any): Observable<any> {
    return this.http.get(url, options).pipe(
      catchError((err) => this.handleHttpError(err))
    );
  }

  post(url: string, payload: any, options?: any): Observable<any> {
    return this.http.post(url, payload, options).pipe(
      catchError((err) => this.handleHttpError(err))
    );
  }

  put(url: string, payload: any, options?: any): Observable<any> {
    return this.http.put(url, payload, options).pipe(
      catchError((err) => this.handleHttpError(err))
    );
  }

  delete(url: string, options?: any): Observable<any> {
    return this.http.delete(url, options).pipe(
      catchError((err) => this.handleHttpError(err))
    );
  }

  private handleHttpError(err: HttpErrorResponse): Observable<any> {
    console.error({ message: `There was an error processing your request. Please try again. (error code: ${err.status})` });

    this.errorSource.next();

    return throwError(err);
  }

  compare(a, b) {
    if (a.sortOrder < b.sortOrder) {
      return -1;
    }
    if (a.sortOrder > b.sortOrder) {
      return 1;
    }
    return 0;
  }
}