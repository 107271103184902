import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  private routeSource: Subject<any> = new Subject<any>();
  route$ = this.routeSource.asObservable();

  constructor(private router: Router,
              private storage: StorageService) { }

  get route() {
    return this.storage.route;
  }
  set route(route: string) {
    this.storage.route = route;
    this.routeSource.next(route);
  }

  /**
   * Navigate the user to somewhere in the app
   * @param path Path to navigate to
   */
  navigate(path) {
    this.router.navigate([path], { queryParamsHandling: 'merge' });
  }
}
