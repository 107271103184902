import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  overlayLoader: boolean;
  loading: boolean;

  on() {
    this.loading = true;
  }

  off() {
    this.loading = false;
  }

  overlayOn() {
    this.overlayLoader = true;
  }

  overlayOff() {
    this.overlayLoader = false;
  }

  get isLoading() {
    return this.loading;
  }

}
