import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from '../../shared/services/api.service';
import { Permissions } from 'src/app/shared/enums/Permissions.enum';
import { ToastService } from 'src/app/shared/services/toast.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private errorSource: Subject<any> = new Subject<any>();
  error$ = this.errorSource.asObservable();

  private sessionVerifiedSource: Subject<any> = new Subject<any>();
  sessionVerified$ = this.sessionVerifiedSource.asObservable();

  excludedPaths: any = [];

  constructor(
    private apiService: ApiService,
    private storageService: StorageService,
    private configurationService: ConfigurationService,
    private toast: ToastService) {
      let publicUrl = this.configurationService.get('publicUrl');
      if (publicUrl !== null) this.excludedPaths = [publicUrl];
    }
  
  validateToken(token: string) {
    this.storageService.bearerToken = token;
    this.apiService.getUser().subscribe(response => {
      this.storageService.emailAddress = response.emailId;
      this.storageService.userId = response.userId;
      this.validateUser(response);
    }, error => {
        this.notifyOfError(error);
    });
  }
  
  anonymousSession() {
    setTimeout(() => {
      this.sessionVerifiedSource.next(false);
    });
  }

  validateUser(response: any) {
    this.sessionVerifiedSource.next(this.isSupport(response));
  }

  isSupport(response: any) {
    return response[Permissions.SUPPORT] ? true : false;
  }

  isExcludedPath() {
    let currentPath = location.pathname;
    return this.excludedPaths.some(excludedPath => currentPath.startsWith(excludedPath));
  }

  notifyOfError(err: any) {
    this.toast.invalidSession(err);
  }
}
